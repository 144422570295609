<template>
  <div>
    <div class="modal fade" ref="mdlNuevaPartidaEntrada" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" role="dialog"  aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">Nueva partida</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Selección del artículo -->
            <div v-if="bArticuloSeleccionado == false" style="min-height: 350px;">
              <div class="row">
                <div class="col-md-6">
                  <label for="dato">Dato</label>
                  <filtrador
                    v-on:dato-actualizado="
                      dato = $event,
                      refrescarArticulos()
                    "
                    :datoini="dato"
                    placeholder="Dato del artículo"
                  ></filtrador>
                </div>
                <div class="col-md-3">
                  <label>División</label>
                  <select
                    class="form-select"
                    v-model="idFamilia" 
                    @change="cargarFamilia();"
                    name="division"
                  >
                    <option :value="null">Cualquiera</option>
                    <option v-for="familia in familias" :key="familia.id" :value="familia.id">{{familia.division}}</option>
                  </select>
                </div>
                <div class="col-md-3">
                  <label>Familia</label>
                  <input type="text" placeholder="Familias" class="form-control" disabled v-model="familia.familia">
                </div>
              </div>
              <br>
              <div class="table-responsive">
                <table id="tabla-articulos" class="table table-hover">
                  <thead>
                    <tr>
                      <th style="width:46px"></th>
                      <th>ID</th>
                      <th>Nombre</th>
                      <th>División</th>
                      <th>Familia</th>
                      <th style="width: 65px">Acción</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="articulo in articulos" :key="`art-${articulo.id}`">
                      <td>
                        <div style="min-height: 30px; display: inline-block">
                          <a
                            href="#"
                            onclick="return false;"
                            @click="mostarGaleriaFotosArticulo(articulo.id)"
                            v-if="articulo.foto_principal"
                          >
                            <img
                              :src="`${API}/articulos/${articulo.id}/fotos/principal?tipo_foto=64x64&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                              style="width:30px; height:30px;"
                              class="imagen-articulo"
                              v-if="articulo.foto_principal"
                            />
                          </a>
                          <img
                            :src="inventarioIcon"
                            style="width:30px; height:30px;"
                            v-if="!articulo.foto_principal"
                          />
                        </div>
                      </td>
                      <td>{{articulo.id}}</td>
                      <td>{{articulo.nombre}}</td>
                      <td>{{articulo.familia ? articulo.familia.division : ''}}</td>
                      <td>{{articulo.familia ? articulo.familia.familia : ''}}</td>
                      <td>
                        <button @click="seleccionarArticulo(articulo)"
                          class="btn btn-success btn-sm">
                          Seleccionar
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="text-center" v-if="ta >= 10">
                <paginador
                  :pag="page"
                  :totpag="totpag"
                  :epp="epp"
                  @cargar-pagina="cargarPagina"
                  @epp-actualizado="
                    epp = $event,
                    refrescarArticulos()
                  "
                ></paginador>
              </div>
            </div>
            <!-- Fin de selección de artículo -->

            <!-- Preparación de la partida -->
            <div v-if="bArticuloSeleccionado">
              <div class="row">
                <div class="col-md-4">
                  <div class="divFotoArticulo">
                    <a
                      href="#"
                      onclick="return false;"
                      @click="mostarGaleriaFotosArticulo(articuloSeleccionado.id)"
                      v-if="articuloSeleccionado.foto_principal"
                    >
                      <img
                        :src="`${API}/articulos/${articuloSeleccionado.id}/fotos/principal?tipo_foto=256x256&tmp=${Math.random().toFixed(10).substring(2)}&_tk=${tk}`"
                        v-if="articuloSeleccionado.foto_principal"
                        class="img-thumbnail"
                      />
                    </a>
                    <img
                      :src="inventarioIcon"
                      v-if="!articuloSeleccionado.foto_principal"
                      class="img-thumbnail"
                    />
                  </div>
                </div>
                <div class="col-md-8">
                  <div class="row">
                    <div class="col-md-12">
                      <label>Nombre del artículo</label>
                      
                      <div class="input-group">
                        <input
                          type="text"
                          v-model="articuloSeleccionado.nombre"
                          class="form-control"
                          readonly
                        />
                        <button
                          class="btn btn-success"
                          @click="bArticuloSeleccionado=false"
                          title="Cambiar artículo"
                        >
                          <i class="mdi mdi-refresh"></i>
                          Cambiar
                        </button>
                      </div>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-12">
                      <label>Descripción del Artículo</label>
                      <textarea
                        class="form-control"
                        name="descripcion_articulo"
                        rows="4"
                        v-model="articuloSeleccionado.descripcion"
                        disabled
                      ></textarea>
                    </div>
                  </div>
                  <br>
                  <div class="row">
                    <div class="col-md-4">
                      <label>Cantidad</label>
                      <input
                        type="number"
                        v-model="nuevaPartida.cantidad"
                        class="form-control text-right"
                        name="cantidad"
                        ref="cantidad"
                        min="0"
                        onclick="this.select()"
                        oninput="this.value = Math.abs(this.value)"
                        :disabled="nuevaPartida.id_articulo == null"
                      />
                    </div>
                    <div class="col-md-4">
                      <label>Unidad de medida</label>
                      <span class="form-control">
                        {{articuloSeleccionado.unidad_medida.abreviatura}}
                      </span>
                      <small class="text-danger" v-show="articuloSeleccionado.id_unidad_medida == null">
                        * Se necesita registrar una unidad de medida al artículo, 
                        da click 
                        <a @click="editarArticulo(articuloSeleccionado.id)">
                          aquí
                        </a>
                        para agregarla
                      </small>
                    </div>

                    <div class="col-md-4" v-show="articuloSeleccionado.id_unidad_medida != null">
                      <label>Stock en almacén</label>
                      <div class="input-group">
                        <input
                          type="number"
                          name="cantidades"
                          class="form-control text-center"
                          :value="articuloSeleccionado.stock_total"
                          disabled
                          readonly
                        >
                        <span class="input-group-text">
                          {{articuloSeleccionado.unidad_medida.nombre}}{{articuloSeleccionado.stock_total > 1 ? 's': '' }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Fin de la preparación de la partida -->
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-light"
              data-bs-dismiss="modal"
            >
              <i class="mdi mdi-close"></i>
              Cancelar
            </button>
            <button 
              type="button" 
              class="btn btn-success" 
              @click="agregarPartida()" 
              v-if="bArticuloSeleccionado == true"
            >
              <i class="mdi mdi-plus"></i>
              Agregar
            </button>
          </div>
        </div><!-- /.modal-content -->
      </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
    <cmp-galeria-fotos 
      ref="galeria_entrada_articulo"
      :data_gallery="`galeria-nueva-entrada-articulo`"
    />
  </div>
</template>


<script>
import FamiliaArticulosSrv  from '@/services/inventario/FamiliaArticulosSrv.js'
import ArticuloSrv from '@/services/inventario/ArticuloSrv.js'
import inventarioIcon from '@/assets/img/inventario/inventario.png'
import Filtrador from '@/components/Filtrador.vue'
import Paginador from '@/components/Paginador.vue'
import CmpGaleriaFotos from "../CmpGaleriaFotos.vue"
import Modal from 'bootstrap/js/dist/modal';
import {APIINV as API} from '@/API.js'

export default {
  components: {
    Filtrador,
    Paginador,
    CmpGaleriaFotos
  },
  data() {
    return {
      dato: '',
      page: 1,
      epp: parseInt(localStorage.getItem('argusblack.mdlNuevaPartidaEntrada.por_pagina') || 10),
      ta: 0, // Total de Articulos
      totpag: 0, // Total de páginas

      API : API,
      tk: localStorage.getItem('argusblack.token'),
      inventarioIcon: inventarioIcon,
      bArticuloSeleccionado: false,

      articuloSeleccionado: {
        id: null,
        nombre: '',
        descripcion: '',
        foto_principal: null,
        id_unidad_medida: null,
        stock_total: 0,
        unidad_medida: {
          nombre: '',
          abreviatura: ''
        },
      },

      idFamilia: null,
      articulos: [],

      familia: {
        familia: null,
        division: '',
        descripcion: '',
      },

      familias: [],

      nuevaPartida: {
        id_familia: null,
        id_articulo: null,
        cantidad: 0,
        id_partida_reqart: null,
        division: '',
        familia: null,
        abreviatura_unidad_medida: ''
      },
    }
  },
  created() {
    this.cargarFamilias()
  },
  methods: {
    agregarPartida() {
      let self = this

      if (!self.verificarDatosCorrectos()) return

      var articulo = self.articulos.filter(articulo => articulo.id == self.nuevaPartida.id_articulo)

      let nuevaPartida = Object.assign({}, self.nuevaPartida)

      Object.assign(nuevaPartida, {
        nombre_articulo: articulo[0].nombre,
        descripcion_articulo: articulo[0].descripcion,
        foto_principal: articulo[0].foto_principal,
        abreviatura_unidad_medida: articulo[0].unidad_medida.abreviatura
      })
      
      this.$emit('partida:lista', nuevaPartida)
      
      this.limpiarNuevaPartida()

      var modal = Modal.getInstance(self.$refs.mdlNuevaPartidaEntrada)
      modal.hide()
    },

    cargarFamilia() {
      let self = this

      let idFamilia = self.idFamilia

      iu.spinner.mostrar('#tabla-articulos')
      
      if(idFamilia == null){
          self.familia = {
            familia: null,
            division: '',
            descripcion: '',
          }
          self.articulos = []
          self.id_articulo = null
      } 

      if(idFamilia == null) {
        self.refrescarArticulos()
        return
      }

      FamiliaArticulosSrv.familiaJSON(idFamilia).then(response => {
        self.familia = response.data
        self.refrescarArticulos()
        Object.assign(self.nuevaPartida, {
          division: self.familia.division,
          familia: self.familia.familia
        })
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudo cargar la familia de artículo '+idFamilia
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarFamilias() {
      var self = this

      let params = {
        sin_paginacion: true,
        solo_idfamdiv: true,
      }

      FamiliaArticulosSrv.familiasJSON(params).then(response => {
        self.familias = response.data
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar las familias de artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      })
    },

    cargarPagina: function(n) {
      var self = this
      self.page = n
      self.refrescarArticulos({ page: n })
    },

    editarArticulo(idArticulo) {
      var modal = Modal.getInstance(this.$refs.mdlNuevaPartidaEntrada)
      modal.hide()
      this.$router.push({name: 'edicionArticulo', params: {id: idArticulo}})
    },

    limpiarNuevaPartida() {
      this.articulos = []
      this.bArticuloSeleccionado = false

      Object.assign(this.nuevaPartida, {
        id_articulo: null,
        id_familia: null,
        cantidad: 0,
      })
    },

    mostrar() {
      var modal = new Modal(this.$refs.mdlNuevaPartidaEntrada)
      modal.show()
    },

    mostarGaleriaFotosArticulo: function(idArticulo) {
      var self = this
      self.$refs.galeria_entrada_articulo.mostrarGaleria(idArticulo)
    },

    refrescarArticulos() {
      let self = this

      iu.spinner.mostrar('#tabla-articulos')

      let params = {
        dato: self.dato,
        id_familia: self.idFamilia,
        page: self.page,
        epp: self.epp,
        orden: 'desc',
        con_familia: true,
        con_unidad_medida: true
      }

      ArticuloSrv.articulosJSON(params).then(response => {
        localStorage.setItem('argusblack.mdlNuevaPartidaEntrada.por_pagina', self.epp)
        let page = response.data
        self.articulos = page.data
        self.ta = page.total
        self.totpag = page.last_page

        if (self.totpag < self.page) {
          self.page = self.totpag
          self.refrescarArticulos()
        }
      }).catch(error => {
        let mensaje
        try {
          mensaje = error.response.data.message
        } catch (e) {
          mensaje = 'No se pudieron cargar los artículos'
        }
        iu.msg.error(mensaje)
        console.log(error)
      }).finally(() => {
        iu.spinner.ocultar('#tabla-articulos')
      })
    },

    seleccionarArticulo(articulo) {
      let self = this

      Object.assign(self.articuloSeleccionado, articulo)

      Object.assign(self.nuevaPartida, {
        id_familia: articulo.id_familia,
        id_articulo: articulo.id,
        cantidad: 0,
        id_partida_reqart: null,

        division: articulo.familia.division,
        familia: articulo.familia.familia,
        abreviatura_unidad_medida: ''
      })

      self.bArticuloSeleccionado = true
    },

    verificarDatosCorrectos(){
      var self = this
      let nvapart = self.nuevaPartida

      if(nvapart.cantidad == null || nvapart.cantidad < 1){
        iu.msg.warning('Se necesita una cantidad válida y que sea mínimo 1')
        self.$refs.cantidad.focus()
        return false
      }

      return true
    }
  }
}
</script>

<style>
.divFotoArticulo {
  width:100%;
  height: 100%;
  text-align: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
}

.divFotoArticulo img {
  width: 100%;
}
.imagen-articulo {
  border-radius: 3px 3px 3px 3px;
  -moz-border-radius: 3px 3px 3px 3px;
  -webkit-border-radius: 3px 3px 3px 3px;
  border: 0px solid #000000;
  margin-right: 5px;
}
</style>